import { graphql } from "gatsby"
import React, {useState, useEffect} from "react"
import ReactFullpage from "@fullpage/react-fullpage"
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import Header from "../components/Header"
import Footer from "../components/Footer"
import HeroLanding from "../components/Banner/HeroLanding"
import SiteMetadata from "../components/SiteMetadata"
import StarwrapThree from "../components/StarwrapThree"
import FeatureLandingNew from "../components/Banner/FeatureLandingNew"
import Eshop from "../components/Banner/EShop"
import News from "../components/Banner/News"
import SeriesLanding from "../components/Banner/SeriesLanding"



const IndexPage = ({ data, location }) => {
  // Get current locale and link
  const siteLanguages = require('../data/languages')
  const url = location.pathname
  const { langs, defaultLangKey } = siteLanguages
  let langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`;
  let langsMenu = getLangs(
    langs,
    langKey,
    getUrlForLang(homeLink, url)
  )
  
  // definitions for graphQL query results 
  const featuredContent = data.featuredContent.edges

  // definitions for fullpagejs
  const SEL = 'section';
  const SECTION_SEL = `.${SEL}`;

  const pluginWrapper = () => {
    require ('../../static/fullpage.fadingEffect.min.js');
  };


  function onLeave(origin, destination, direction) {
    if(direction==="up"){
     setHeaderVisible(true)
    } else if(direction==="down"){
      setHeaderVisible(false)
    }
  }


  const [headerVisible, setHeaderVisible] = useState(true)

  useEffect(()=>{

    // show nav bar when scroll up, hide when scroll down, fix for pages with fullpagejs
    const header = document.querySelector('.head');
    if (headerVisible){
      header.classList.remove('hidden')
      // gsap.to(".head", {autoAlpha: 1})
    } else {
      header.classList.add('hidden')
      // gsap.to(".head", {autoAlpha: 0})
    }
  },[headerVisible])

  useEffect(()=>{
    window.addEventListener('resize', () => { 
      document.body.height = window.innerHeight;
    })

    return () => {
      window.removeEventListener('resize', () => { 
        document.body.height = window.innerHeight;
      })
    }
  }, [])

  return (
    <>
    <Header locale={langKey} langsMenu={langsMenu}/>
      <SiteMetadata title="ZIPPO China" description="自1932年问世以来，ZIPPO便是世界上最负盛名的品牌之一。我们成功地将一枚小小的打火机，打造成为了一种文化符号。ZIPPO一直是不断激发灵感的精神图腾。仅用「打火」这一简单动作，ZIPPO便可启迪时代精神和灵感，照亮过往与未来。" languages="zh-CN"/>
      {typeof window !== 'undefined' && ( 
      <ReactFullpage

        // fullpage options
        licenseKey={'6246B3C1-F1D84F4C-BE4DB156-9B313FEC'} 
        navigation
        anchors={['video', 'stars', 'featured', 'ip', 'news', 'eshop']}
        sectionSelector={SECTION_SEL}
        pluginWrapper={pluginWrapper}
        bigSectionsDestination={"top"}
        normalScrollElements={".eshop"}
        fadingEffectKey= {'emlwcG8uY29tLmNuX1EwMlptRmthVzVuUldabVpXTjBmUUE='}
        fadingEffect= {true}
        onLeave={onLeave}
        scrollOverflow={false}


        render={comp => (
          <ReactFullpage.Wrapper>
            <div className="section">
              <HeroLanding hero={data.hero} />
            </div>
            <div className="section">
            <StarwrapThree starfireimage={data.starfireimage} salute={data.salute} bg={data.landingBg} data={data.starwrap}/>
            </div>
            <div className="section">
              <FeatureLandingNew locale={langKey} content={featuredContent} svg={data.whitefire} mobileBg={data.mobileFcBg}/>
            </div>
            <div className="section">
              <SeriesLanding locale={langKey} content={data.ip.edges[0]} />
            </div>
            <div className="section">
              <News locale={langKey} content={data.news.edges} />
            </div>
            <div className="section">
                <Eshop locale={langKey} content={data.eshop} />
            </div>
            <div className="section">
              <Footer locale={langKey}/>
            </div>
          </ReactFullpage.Wrapper>
        )}
      />
      )}
    </>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery_CN {
    hero: contentfulLayoutHero(title: {eq: "Home > Hero"}, node_locale: {eq: "zh-CN"}) {
      svgImage {
        file {
          url
        }
      }
      title
      activateBackgroundVideo
      backgroundVideoButton
      backgroundVideo {
        file {
          contentType
          url
        }
      }
      hdBackgroundVideo {
        file {
          contentType
          url
        }
      }
      backgroundVideoMobile {
        file {
          contentType
          url
        }
      }
      backgroundImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      chineseHeadline
      headline
    }
    starfireimage: contentfulAsset(title: {eq: "LandingWhiteFireImg"}) {
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    landingBg: contentfulAsset(title: {eq: "landing background top"}) {
      fluid(quality: 20, maxWidth: 1600){
        ...GatsbyContentfulFluid_withWebp
      }
    }
    starwrap: contentfulHomeStarMenu(node_locale: {eq: "zh-CN"}) {
      firstMenu
      firstSubMenu
      kvText
      distortionFilter {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      firstImageMobile {
        fluid(quality: 20, maxWidth: 800) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
      }
      firstImagePc {
        description
        fluid(quality: 80, maxWidth: 2600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      secondImageMobile {
        fluid(quality: 20, maxWidth: 800) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
      }
      secondImagePc {
        description
        fluid(quality: 80, maxWidth: 2600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      secondMenu
      secondSubmenu
      thirdImageMobile {
        fluid(quality: 20, maxWidth: 800) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
      }
      thirdImagePc {
        fluid(quality: 80, maxWidth: 2600) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
      }
      thirdMenu
      thirdSubmenu
    }
    starwrapMobile: allContentfulAsset(filter: {node_locale: {eq: "en-US"}, title: {regex: "/STARMENU MOBILE OVERLAY/"}}) {
      nodes {
        fluid(quality: 20, maxWidth: 800){
          ...GatsbyContentfulFluid_withWebp
          aspectRatio
        }
        description
      }
    }
    featuredContent: allContentfulHomeFeatured(sort: {fields: title, order: DESC}, filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          title
          desktop: productImage {
            fixed(quality: 90, width: 95) {
              ...GatsbyContentfulFixed_withWebp
            }
            title
          }
          mobile: productImage {
            fixed(quality: 90, width: 80) {
              ...GatsbyContentfulFixed_withWebp
            }
            title
          }
          tablet: productImage {
            fixed(quality: 90, width: 100) {
              ...GatsbyContentfulFixed_withWebp
            }
            title
          }
          featuredImages {
            fluid(quality: 50, maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          tags
          layoutType
          order
        }
      }
    }
    whitefire: contentfulAsset(title: {eq: "white fire"}) {
      fluid(maxWidth: 500){
        ...GatsbyContentfulFluid_withWebp
      }
    }
    eshop: contentfulHomeEshop(node_locale: {eq: "zh-CN"}) {
      header
      subHeader
      ctaTitle
      ctaLink
      backgroundImage {
        fluid(quality: 60, maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    news: allContentfulNews(filter: {node_locale: {eq: "zh-CN"}}, sort: {order: DESC, fields: publishedDate}) {
      edges {
        node {
          slug
          publishedDate(formatString: "YYYY MMM DD")
          title
        }
      }
    }
    ip: allContentfulHomeIpSeries(filter: {node_locale: {eq: "zh-CN"}}) {
      edges {
        node {
          backgroundImage {
            fluid(quality: 50, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
            }
          }
          ctaLink
          ctaTitle
          subHeader
          header
        }
      }
    }
    mobileFcBg: contentfulAsset(title: {eq: "MobileFcBgImg"}) {
      fluid(quality: 60){
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`
